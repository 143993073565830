
.parentCollapse {
	background: rgba(29, 29, 27, 0.4);
	border: none;
	margin-bottom: 10px;
	border-radius: 8px;
}

.childrenCollapse {
	background: linear-gradient(180deg, rgba(6, 25, 25, 0.328579) 0%, rgba(0, 0, 0, 0.6) 100%);
	border: none;
	margin-bottom: 10px;
	border-radius: 8px;
}

.arrowActive {
	transform: rotate(180deg) !important;
}

.arrowActive {
	transform-origin: 50% 79%;
}

.arrow {
	transform-origin: 50% 66%;
}

:global(.ant-collapse-content) {
  border-top: none;
	background: inherit;
	color: white;
}

:global(.ant-collapse-content > .ant-collapse-content-box) {
	padding: 2px 10px 15px 10px;
}

.panelHeader {
	display: flex;
}

.panelHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 2px;
}

.findingsContainer {
	padding: 12px;
	background: linear-gradient(154.62deg, rgba(5, 18, 18, 0.438105) 9.92%, rgba(5, 18, 18, 0.438105) 9.92%, rgba(0, 0, 0, 0.8) 123.12%);
	border-radius: 8px;
}

.findingsContainer:not(:last-child) {
  margin-bottom: 12px;
}


.findingsContainer span {
	font-size: 12px;
	font-weight: 300;
	line-height: 18px;
	letter-spacing: 0.1666666716337204px;
	text-align: left;
}

.findingDate {
	display: flex;
	align-items: center;
	gap: 16px
}

.findingsContainer .findingStatus {
	color: #5d7083;
}


.childPanelHeader {
	display: flex;
	flex-direction: column;
	color: white;
}

.findingBody{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.findingActionItemContainer{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.findingBody hr{
  border-top: 1px solid #C2C2C2;
	width: 100%;
	margin-top: -1px;
	margin-bottom: -1px;
	opacity: 60%;
}

.childPanelSubHeader{
	color: #797d7e;
	font-weight: 600 !important;
	display: flex;
	justify-content: space-between;
}

.checkListName{
	font-weight: 300;
}

/* Empty state */

.noResult {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	padding: 10% 0 10% 0;
	color: white;
}
	
.noResult h2 {
	color: white;
	font-family: 'Museo Sans';
	margin-top: 10px;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	letter-spacing: 0.194px;
	text-align: left;
}

.badge{
  display: inline-block;
  padding: 4px 8px;
	padding-left: 18px;
  border-radius: 8px;
	position: relative;
	font-weight: 600;
}

.badge::before{
  content: '';
  position: absolute;
  top: 40%;
  left: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.badge-high{
	  color: white;
		background-color: #8D1017;
}

.badge-high::before{
  background-color: red;
}

.badge-medium{
	  color: white;
		background-color: #FDB924;
}

.badge-medium::before{
  background-color: #FFE500;
}

.badge-low{
	  color: white;
		background-color: #0B882E;
}

.badge-low::before{
  background-color: #00FF5D;
}

.statusCountContainer{
	display: flex;
	gap: 24px
}

.statusCount{
	color: white;
	font-size: 12px;
	font-weight: 300;
}

.statusCount .statusCountNo{
	font-size: 14px;
	font-weight: 600;
}

.accordionHeader {
	color:#fff;
}

.assuranceReferenceNumber {
	white-space: pre-line;
}